<!-- **************************************************************************
     TEMPLATE
     ************************************************************************** -->

<template>
  <div>
    <v-app-bar app color="transparent" dark dense flat>
      <v-btn icon :to="{ name: homeRoute }" class="no-active">
        <v-icon>fas fa-home</v-icon>
      </v-btn>
      <div class="d-flex align-center"></div>
      <v-spacer></v-spacer>
      <v-btn v-if="isLoggedIn" to="/admin" text>
        <span class="mr-2">Admin</span>
        <v-icon>fas fa-user-shield</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container class="mb-15">
        <router-view />
      </v-container>
    </v-main>
    <AppFooter></AppFooter>
  </div>
</template>

<!-- **************************************************************************
     SCRIPT
     ************************************************************************** -->

<script>
// NPM
import { get } from "vuex-pathify"

// PROJECT
import AppFooter from "./../AppFooter"
import { routeNames } from "./../../router/config"

export default {
  name: "PublicLayout",
  components: {
    AppFooter
  },
  computed: {
    isLoggedIn: get("auth/isAuthenticated"),
    homeRoute: () => routeNames.home
  },
  methods: {}
}
</script>
