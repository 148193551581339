/**
 * FILE: firebase.js
 * Google Firebase cpnnection management and utility file. The application configuration is
 * loaded based upon the environment. Utility methods are established to help during
 * development and to enforce DRY.
 */

// NPM
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/analytics"
import "firebase/functions"

// PROJECT
import store from "./../store"

// CONFIG LOADED FROM .ENV FILES
const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DB,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID
}

firebase.initializeApp(firebaseConfig)

// UTILS
const db = firebase.firestore()
const auth = firebase.auth()

//analytics.initialize
var analytics = {
  logEvent: () => {},
  Item: () => {}
}
firebase.analytics.isSupported().then((isSupported) => {
  if (isSupported) {
    try {
      analytics = firebase.analytics()
    } catch (error) {
      /* eslint-disable no-console */
      console.log("Firebase Error initilizing Analytics.  Error: ", error)
      /* eslint-enable no-console */
    }
  }
})

// holds the URL used to reference the postDonor function
const functionsUrl = process.env.VUE_APP_FUNCTIONS

// SET USER STORAGE PERISTANCE
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)

// FIRESTORE TYPES
const { Timestamp, GeoPoint, FieldValue } = firebase.firestore

// COLLECTIONS REFERENCES
const collections = Object.freeze({
  faq: "faq",
  families: "families",
  cards: "cards",
  children: "children",
  gifts: "gifts",
  donors: "donors",
  userProfiles: "user-profile",
  appSettings: "app-settings",
  ornaments: "ornaments"
})

// COLLECTION OBJECTS
const faqCollection = db.collection(collections.faq)
const familiesCollection = db.collection(collections.families)
const cardsCollection = db.collection(collections.cards)
const childrenCollection = db.collection(collections.children)
const giftsCollection = db.collection(collections.gifts)
const donorsCollection = db.collection(collections.donors)
const usersCollection = db.collection(collections.userProfiles)
const appSettingsCollection = db.collection(collections.appSettings)

// LOGIN MANAGEMENT

/**
 * Logs in the user with the provided credentials using the username and password
 * method. Returns a success object if pass or error object if fail.
 * @param {*} credentials
 */
async function loginWithEmailAndPassword(credentials) {
  const { user } = await auth.signInWithEmailAndPassword(
    credentials.email,
    credentials.password
  )

  return await fetchUserClaims(user)
}

/**
 * Logs in the user with federated Google authentication. Returns a success object if pass or error
 * object if fail.
 */
async function loginWithGoogle() {
  const provider = new firebase.auth.GoogleAuthProvider()
  const { user } = await auth.signInWithPopup(provider)

  return await fetchUserClaims(user)
}

/**
 * Fetches the user's claims from Google's firestore authentication system
 * @param {*} user
 */
async function fetchUserClaims(user) {
  const { claims } = await user.getIdTokenResult(true)

  store.set("auth/claims", claims)
  store.set("auth/user", user)

  return { admin: claims.admin, editor: claims.editor, gifts: claims.gifts }
}

async function logout() {
  await auth.signOut()

  store.set("auth/user", null)
  store.set("auth/claims", {})
}

// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce
const EventName = Object.freeze({
  VIEW_ITEM: firebase.analytics.EventName.VIEW_ITEM,
  SELECT_ITEM: firebase.analytics.EventName.SELECT_ITEM,
  BEGIN_CHECKOUT: firebase.analytics.EventName.BEGIN_CHECKOUT,
  REMOVE_FROM_CART: firebase.analytics.EventName.REMOVE_FROM_CART,
  ADD_TO_CART: firebase.analytics.EventName.ADD_TO_CART,
  PURCHASE: firebase.analytics.EventName.PURCHASE,
  SEARCH: firebase.analytics.EventName.SEARCH,
  PAGE_VIEW: firebase.analytics.EventName.PAGE_VIEW,
  SCREEN_VIEW: firebase.analytics.EventName.SCREEN_VIEW,
  VIEW_PROMOTION: firebase.analytics.EventName.VIEW_PROMOTION,
  SELECT_PROMOTION: firebase.analytics.EventName.SELECT_PROMOTION,
  CANCEL_EMAIL: "cancel-email-dialog",
  CANCEL_PROMOTION: "cancel-promotion"
})

// EXPORT UTILS/REFS
export {
  db,
  auth,
  analytics,
  EventName,
  functionsUrl,
  firebase,
  collections,
  faqCollection,
  familiesCollection,
  cardsCollection,
  giftsCollection,
  donorsCollection,
  usersCollection,
  childrenCollection,
  appSettingsCollection,
  loginWithEmailAndPassword,
  loginWithGoogle,
  fetchUserClaims,
  logout,
  Timestamp,
  GeoPoint,
  FieldValue
}
