/**
 * FILE: auth.js
 * This holds all the vuex auth state variables at that are needed across the application.
 */

// NPM
import { make } from "vuex-pathify"

// PROJECT

const state = {
  user: null,
  claims: {}
}

const getters = {
  ...make.getters(state),

  isAuthenticated: () => {
    return state.user && (state.claims.editor || state.claims.gift)
      ? true
      : false
  },

  roles: () => {
    if (state.user && state.claims) {
      //return all claims keys that are true
      return getKeyByValue(state.claims, true)
    } else {
      return []
    }
  }
}

const actions = {
  ...make.actions(state)
}

const mutations = make.mutations(state)

function getKeyByValue(object, value) {
  return Object.keys(object).filter((key) => object[key] === value)
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
