// index.js - STORE

// NPM
import Vue from "vue"
import Vuex from "vuex"

// PROJECT
import pathify from "./pathify"
import auth from "./auth"
import treeStore from "./treeStore"
import appSettings from "./appSettings"
import cookies from "./cookies"

//pathify.debug()

Vue.use(Vuex)

//store
const store = new Vuex.Store({
  modules: {
    auth: auth,
    cookies: cookies,
    ...appSettings,
    ...treeStore
  },

  plugins: [pathify.plugin]
})

export default store
window.store = store
