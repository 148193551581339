/**
 * FILE: treeStore.js
 * This holds all the state related to the tree, both in graphical and table forms.
 * lodash is a great library for working with arrays.  It can sort/filter/etc arrays of objects.
 * axios is used for all http requests.  Specifically, it can communicate to open elements of Firebase.
 * pathify is a wrapper around Vuex, the state management system used by Vue.js.
 */

import { debounce } from "lodash"
import { make } from "vuex-pathify"
import { analytics, EventName } from "./../services/firebase"

const state = {
  filter: {
    boys: true,
    girls: true,
    indivGifts: true,
    minAge: 0,
    maxAge: 216,
    searchString: ""
  }
}

var debounceAnalytics = debounce(async function (payload) {
  analytics.logEvent(EventName.SEARCH, {
    search_term: state.filter.searchString,
    filter: payload.path,
    value: payload.value
  })
}, 1000)

const getters = {
  ...make.getters(state)
}

const actions = {}

const mutations = {
  ...make.mutations(state),

  SET_FILTER(state, payload) {
    debounceAnalytics(payload)
    if (payload.path) {
      state.filter[payload.path] = payload.value
    } else {
      state.filter = payload
    }
  }
}

export default {
  treeStore: {
    namespaced: true,
    state: state,
    mutations,
    getters,
    actions
  }
}
