<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        class="ma-6"
        color="indigo lighten-1"
        elevation="10"
        tile
        dark
        max-width="250"
        @click="$router.push(treeLink)"
      >
        <v-img
          class="white--text align-end"
          height="200px"
          width="250px"
          contain
          src="/img/Tree.png"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)"
        >
          <v-card-title>Virtual Tree</v-card-title>
        </v-img>

        <v-card-subtitle class="text--white">
          <div>
            View a virtual tree where each ornament represents an opportunity to
            make a childs dreams come true
          </div>
        </v-card-subtitle>

        <v-fade-transition>
          <v-overlay v-if="hover" absolute color="rgba(0,0,0,.3)">
            <v-btn class="secondary" :to="treeLink">The Virtual Tree</v-btn>
          </v-overlay>
        </v-fade-transition>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
import { routeNames } from "./../../router/config"

export default {
  name: "TreeCard",
  data: () => ({
    overlay: false
  }),
  computed: {
    treeLink() {
      return { name: routeNames.tree }
    }
  }
}
</script>
