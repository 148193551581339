<!-- **************************************************************************
     TEMPLATE
     ************************************************************************** -->

<template>
  <div class="text-left justify-left">
    <div class="editor">
      <EditorContent class="editor__content" :editor="editor" />
    </div>
  </div>
</template>

<!-- **************************************************************************
     SCRIPT
     ************************************************************************** -->

<script>
import { Editor, EditorContent } from "@tiptap/vue-2"
import StarterKit from "@tiptap/starter-kit"
import Link from "@tiptap/extension-link"

export default {
  name: "Banner",
  components: {
    EditorContent
  },
  data() {
    return {
      editor: null
    }
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [StarterKit, Link],
      editable: false
    })
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes("link").href
      const url = window.prompt("URL", previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run()

        return
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run()
    }
  },
  computed: {},
  props: ["value"]
}
</script>

<!-- **************************************************************************
     STYLE
     ************************************************************************** -->

<style scoped lang="scss">
div {
  color: white;
  border-radius: 10px;
}
@import "@/styles/variables.scss";
.editor__content {
  padding: 20px;
  background: rgba(92, 107, 192);
}
</style>
