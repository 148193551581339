<template>
  <v-row justify="space-around">
    <v-sheet
      color="primary lighten-2"
      elevation="12"
      rounded
      class="pa-6 white--text"
      max-width="800px"
    >
      <h2 class="h2 mb-4">Folsom Police Dept. Toy Drive - {{ activeEvent }}</h2>
      <h3 class="h3 mb-4">Sponsored by the Folsom Police Foundation</h3>
      <v-row v-if="banner" class="ma-1">
        <Banner v-model="bannerText" />
      </v-row>
      <v-row>
        <v-col class="d-none d-md-block ma-4">
          <v-img
            height="300px"
            width="400px"
            contain
            class="ma-4"
            elevation="12"
            src="/img/SantaSleigh.jpeg"
          ></v-img>
          <v-img
            height="300px"
            width="400px"
            contain
            class="ma-4"
            elevation="12"
            src="/img/Delivery.JPG"
          ></v-img>
        </v-col>
        <v-col class="mt-5">
          <p>
            If you are looking to make a child's wish come true this Christmas,
            you have come to the right place - just a little early! We are
            working hard getting the site ready. We will have the tree setup on
            or before:
          </p>
          <h2 class="text-center">{{ formattedStartDate }}</h2>
          <p class="mt-5">
            Please come back and choose a child in need after we have finished
            decorating the tree in:
          </p>
          <h2 class="text-center">{{ formattedTimeLeft }}</h2>
          <p class="mt-5">
            Can't wait? You can make a cash donation today to the toy drive on
            the
            <a
              class="white-link"
              target="_blank"
              rel="noopener noreferrer"
              href="http://folsompolicefoundation.org"
              @click="trackRedirect"
              >Folsom Police Foundation</a
            >
            website or by clicking the button below.
          </p>
          <v-row class="justify-center">
            <v-btn
              href="https://www.paypal.com/donate/?cmd=_donations&business=folsompolicefoundation@gmail.com&item_name=Folsom%20Police%20Foundation&currency_code=USD"
              target="_blank"
              class="no-active"
              color="rgba(92, 107, 192)"
              @click="trackRedirect"
              >Donate Now</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
  </v-row>
</template>

<script>
import { get } from "vuex-pathify"
import { analytics, EventName } from "./../services/firebase"
import Banner from "./Banner/Viewer.vue"

export default {
  name: "HomeInactiveContent",
  components: { Banner },
  props: {
    startDate: {
      type: Date,
      default: function () {
        return new Date("November 10, 2023 12:00:00")
      }
    },
    banner: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    bannerText: {
      type: String,
      default: function () {
        return ""
      }
    }
  },
  data() {
    return {
      timerInterval: null,
      timeLeft: null
    }
  },
  computed: {
    activeEvent: get("appSettings/activeEvent"),
    formattedStartDate() {
      const theStartDate = this.startDate
      return theStartDate.toDateString()
    },
    host() {
      return location.hostname
    },
    formattedTimeLeft() {
      const timeLeft = this.timeLeft
      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24))
      const daysString = days ? days + " days" : ""

      const hours = Math.floor(
        (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      const hoursString = hours ? hours + " hours" : ""

      // The largest round integer less than or equal to the result of time divided being by 60.
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
      const minutesString = minutes ? minutes + " minutes" : ""

      // Seconds are the remainder of the time divided by 60 (modulus operator)
      let seconds = Math.floor((timeLeft % (1000 * 60)) / 1000)
      // If the value of seconds is less than 10, then display seconds with a leading zero
      if (seconds < 10) {
        seconds = `0${seconds}`
      }

      // The output in MM:SS format
      return `${daysString} ${hoursString} ${minutesString} ${seconds} seconds`
    }
  },
  mounted() {
    var now = new Date().getTime()
    this.timeLeft = this.startDate.getTime() - now
    this.startTimer()
  },
  methods: {
    startTimer() {
      this.timerInterval = setInterval(() => {
        const now = new Date().getTime()
        this.timeLeft = this.startDate.getTime() - now
      }, 1000)
    },
    trackRedirect() {
      analytics.logEvent(EventName.SELECT_PROMOTION, {
        promotion_ID: "fpf",
        promotion_name: "FPF Donation",
        location_id: "HomeInactiveContent",
        items: [{ item_id: "FPF" }]
      })
      return false
    }
  }
}
</script>
<style scoped lang="scss">
.white-link {
  &:link {
    color: white;
  }
  &:visited {
    color: white;
  }
  &:hover {
    color: white;
  }
  &:active {
    color: white;
  }
}
</style>
