<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        class="ma-6"
        color="indigo lighten-1"
        elevation="10"
        tile
        dark
        max-width="250"
        @click="$router.push(santasListLink)"
      >
        <v-img
          class="white--text align-end"
          height="200px"
          width="250px"
          contain
          src="/img/Spreadsheet.png"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        >
          <v-card-title>Santa's List</v-card-title>
        </v-img>

        <v-card-subtitle class="text--white">
          <div>
            Select a card from Santa's list where each row represents an
            opportunity to make a childs dreams come true
          </div>
        </v-card-subtitle>

        <v-fade-transition>
          <v-overlay v-if="hover" absolute color="rgba(0,0,0,.3)">
            <v-btn class="secondary" :to="santasListLink">Santa's List</v-btn>
          </v-overlay>
        </v-fade-transition>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
import { routeNames } from "./../../router/config"

export default {
  name: "SheetCard",
  computed: {
    santasListLink() {
      return { name: routeNames.santaslist }
    }
  }
}
</script>
