<!-- **************************************************************************
     TEMPLATE
     ************************************************************************** -->

<template>
  <div>
    <v-app-bar app color="primary" dark dense flat>
      <v-btn icon :to="{ name: homeRoute }" class="no-active">
        <v-icon>fas fa-home</v-icon>
      </v-btn>
      <div class="d-flex align-center">
        <h3 class="h3 ml-3">ADMIN</h3>
      </div>
      <v-spacer></v-spacer>
      <v-btn v-if="isAuthenticated" text @click="signOut">
        <span class="mr-2"
          ><span class="text-lowercase">{{ user.email }}</span> Sign Out</span
        >
        <v-icon>fas fa-sign-out-alt</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container class="pt-4 mb-15">
        <router-view />
      </v-container>
    </v-main>
    <AppFooter></AppFooter>
  </div>
</template>

<!-- **************************************************************************
     SCRIPT
     ************************************************************************** -->

<script>
// NPM
import { get } from "vuex-pathify"

// PROJECT
import AppFooter from "./../AppFooter"
import { logout } from "./../../services/firebase"
import { routeNames } from "./../../router/config"

export default {
  name: "AdminLayout",
  components: {
    AppFooter
  },
  computed: {
    isAuthenticated: get("auth/isAuthenticated"),
    user: get("auth/user"),
    homeRoute: () => routeNames.adminHome
  },
  methods: {
    signOut() {
      logout()
      this.$router.push({ name: routeNames.login })
    }
  }
}
</script>
