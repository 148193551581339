/**
 * FILE: cookies.js
 * This holds all the vuex cookies state variables at that are needed across the application.
 */

// NPM
import { make } from "vuex-pathify"

// PROJECT

const state = {
  currentEvent: null
}

const mutations = {
  ...make.mutations(state),
  SET_CURRENT_EVENT: (state, value) => {
    state.currentEvent = value
    localStorage.setItem("COOKIE:currentEvent", value)
  }
}
const getters = {
  ...make.getters(state),

  currentEvent: (state) => {
    state.currentEvent = localStorage.getItem("COOKIE:currentEvent", true)
    return state.currentEvent
  }
}

const actions = {
  ...make.actions(state),
  setCurrentEvent: ({ commit }, value) => {
    commit("SET_CURRENT_EVENT", value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
