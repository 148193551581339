<template>
  <v-row justify="space-around">
    <v-sheet
      color="primary lighten-2"
      elevation="12"
      rounded
      class="pa-6 white--text"
      max-width="800px"
    >
      <h2 class="h2 mb-4">Folsom Police Dept. Toy Drive - {{ activeEvent }}</h2>
      <h3 class="h3 mb-4">Sponsored by the Folsom Police Foundation</h3>
      <v-row>
        <v-col class="d-none d-md-block ma-4">
          <v-img
            height="300px"
            width="400px"
            contain
            class="ma-4"
            elevation="12"
            src="/img/SantaSleigh.jpeg"
          ></v-img>
          <v-img
            height="300px"
            width="400px"
            contain
            class="ma-4"
            elevation="12"
            src="/img/Delivery.JPG"
          ></v-img>
        </v-col>
        <v-col class="mt-5">
          <p>
            Thank you to all who have helped to make a Merry Christmas for so
            many families in our community!
          </p>
          <p>
            Our wishes have been filled and we are no longer accepting gifts or
            toys.
          </p>
          <p>
            If you are looking to donate toys this holiday season, please reach
            out to the following agencies:
          </p>
          <p>
            CHiPs 4 Kids Toy Drive <br />
            11336 Trade Center Drive <br />
            Rancho Cordova, CA
          </p>
          <p>
            CHiPs 4 Kids Toy Drive <br />
            5109 Tyler St <br />
            Rancho Cordova, CA
          </p>
          <p>
            Powerhouse Ministries <br />
            <a
              class="white-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.phmfolsom.org/christmas"
              @click="trackRedirect"
              >Christmas Around Towne</a
            >
            Program. <br />
            (Call before bringing toys to ensure there is still capacity) <br />
            916-983-0658
          </p>
          <p>
            We hope you and your family have a safe, happy and healthy
            Christmas!
          </p>
          <p>
            Sincerely,<br />
            <a
              class="white-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.folsom.ca.us/government/police/get-involved/caps-volunteer"
              >The Folsom CAPS Elves</a
            >
          </p>
        </v-col>
      </v-row>
    </v-sheet>
  </v-row>
</template>

<script>
import { get } from "vuex-pathify"
import { analytics, EventName } from "./../services/firebase"

export default {
  name: "HomePostActiveContent",
  components: {},
  props: {
    endDate: {
      type: Date,
      default: function () {
        return new Date("December 4, 2020 12:00:00")
      }
    }
  },
  data() {
    return {
      timerInterval: null,
      timeLeft: null
    }
  },
  computed: {
    formattedEndDate() {
      const theEndDate = this.endDate
      return theEndDate.toDateString()
    },
    host() {
      return location.hostname
    },
    activeEvent: get("appSettings/activeEvent")
  },
  methods: {
    trackRedirect() {
      analytics.logEvent(EventName.SELECT_PROMOTION, {
        promotion_ID: "powerhouse",
        promotion_name: "Powerhouse Donation",
        location_id: "HomePostActiveContent",
        items: [{ item_id: "Powerhouse" }]
      })
      return false
    }
  }
}
</script>
<style scoped lang="scss">
.white-link {
  &:link {
    color: white;
  }
  &:visited {
    color: white;
  }
  &:hover {
    color: white;
  }
  &:active {
    color: white;
  }
}
</style>
