/**
 * FILE: treeStore.js
 * This holds all the state related to the tree, both in graphical and table forms.
 * lodash is a great library for working with arrays.  It can sort/filter/etc arrays of objects.
 * axios is used for all http requests.  Specifically, it can communicate to open elements of Firebase.
 * pathify is a wrapper around Vuex, the state management system used by Vue.js.
 */

import { make } from "vuex-pathify"
import store from "."
import { appSettingsCollection } from "./../services/firebase"

const state = {
  activeEvent: null,
  banner: false,
  bannerText: "",
  cardHoldHours: 0,
  devModeBanner: true,
  ohShoot: false,
  ohShootText: "",
  ornaments: [],
  selectionMethod: "Tree and List",
  endDate: null,
  startDate: null,
  wishlistUrl: null
}

const getters = {
  ...make.getters(state),
  isActive: () => {
    let now = new Date().getTime()
    let start = new Date(state.startDate).getTime()
    let end = new Date(state.endDate).getTime()

    return now >= start && now <= end
  }
}

const actions = {
  load() {
    appSettingsCollection.doc("production").onSnapshot((doc) => {
      store.set("appSettings/activeEvent", doc.data().activeEvent)
      store.set("appSettings/banner", doc.data().banner)
      store.set("appSettings/bannerText", doc.data().bannerText)
      store.set("appSettings/cardHoldHours", doc.data().cardHoldHours)
      store.set("appSettings/devModeBanner", doc.data().devModeBanner)
      store.set("appSettings/endDate", doc.data().endDate.toDate())
      store.set("appSettings/startDate", doc.data().startDate.toDate())
      store.set("appSettings/selectionMethod", doc.data().selectionMethod)
      store.set("appSettings/ohShoot", doc.data().ohShoot)
      store.set("appSettings/ohShootText", doc.data().ohShootText)
      store.set("appSettings/wishlistUrl", doc.data().wishlistUrl)
    })
  }
}

const mutations = {
  ...make.mutations(state)
}

export default {
  appSettings: {
    namespaced: true,
    state: state,
    mutations,
    getters,
    actions
  }
}
