// index.js - ROUTER

// NPM
import Vue from "vue"
import VueRouter from "vue-router"
import { intersection } from "lodash"

// PROJECTS
import { routeNames } from "./config"
import PublicLayout from "./../components/layouts/Public"
import PrivateLayout from "./../components/layouts/Private"
import Home from "../views/Home.vue"
import { auth } from "./../services/firebase"
import { Role } from "@/services/appOptions"
import store from "./../store"

Vue.use(VueRouter)

let userRoles = []

const routes = [
  {
    path: "/",
    name: routeNames.home,
    meta: { layout: PublicLayout, redrectonOhNo: true },
    component: Home
  },
  {
    path: "/ohno",
    name: routeNames.ohno,
    meta: { layout: PublicLayout },
    component: () => import(/* webpackChunkName: "ohno" */ "../views/OhNo.vue")
  },
  {
    path: "/tree",
    name: routeNames.tree,
    meta: {
      layout: PublicLayout,
      requiresActive: true,
      redrectonOhNo: true
    },
    component: () => import(/* webpackChunkName: "tree" */ "../views/Tree.vue")
  },
  {
    path: "/santaslist",
    name: routeNames.santaslist,
    meta: {
      layout: PublicLayout,
      requiresActive: true,
      redrectonOhNo: true
    },
    component: () =>
      import(/* webpackChunkName: "santa-list" */ "../views/SantasList.vue")
  },
  {
    path: "/about",
    name: routeNames.about,
    meta: { layout: PublicLayout, redrectonOhNo: true },
    component: () => import(/* webpackChunkName: "info" */ "../views/About.vue")
  },
  {
    path: "/privacy",
    name: routeNames.privacy,
    meta: { layout: PublicLayout, redrectonOhNo: true },
    component: () =>
      import(/* webpackChunkName: "info" */ "../views/Privacy.vue")
  },
  {
    path: "/faq",
    name: routeNames.faq,
    meta: { layout: PublicLayout, redrectonOhNo: true },
    component: () => import(/* webpackChunkName: "info" */ "../views/FAQ.vue")
  },
  {
    path: "/login",
    name: routeNames.login,
    meta: { layout: PublicLayout },
    component: () =>
      import(/* webpackChunkName: "security" */ "../views/Login.vue")
  },
  // ===== SECURE CONTENT =====
  {
    path: "/admin",
    redirect: { name: routeNames.admin },
    meta: {
      requiresAuth: true
    },
    component: PrivateLayout,
    children: [
      {
        path: "",
        name: routeNames.adminHome,
        meta: {
          authorize: [Role.EDITOR, Role.GIFT]
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/secure/Dashboard.vue"
          )
      },
      {
        path: "faq",
        name: routeNames.adminFAQ,
        meta: {
          authorize: [Role.EDITOR]
        },
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/secure/FAQ.vue")
      },
      {
        path: "families",
        name: routeNames.adminFamilies,
        meta: {
          authorize: [Role.EDITOR]
        },
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/secure/Families.vue")
      },
      {
        path: "children",
        name: routeNames.adminChildren,
        meta: {
          authorize: [Role.EDITOR]
        },
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/secure/Children.vue")
      },
      {
        path: "donors",
        name: routeNames.adminDonors,
        meta: {
          authorize: [Role.EDITOR]
        },
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/secure/Donors.vue")
      },
      {
        path: "tree",
        name: routeNames.adminTree,
        meta: {
          authorize: [Role.EDITOR]
        },
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/secure/Tree.vue")
      },
      {
        path: "individual-gifts",
        name: routeNames.adminIndividualGifts,
        meta: {
          authorize: [Role.GIFT, Role.EDITOR]
        },
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/secure/Gifts.vue")
      },
      {
        path: "child-gifts",
        name: routeNames.adminChildGifts,
        meta: {
          authorize: [Role.GIFT, Role.EDITOR]
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/secure/ChildGifts.vue"
          )
      },
      {
        path: "families/:id",
        name: routeNames.adminFamily,
        meta: {
          authorize: [Role.EDITOR]
        },
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/secure/Family.vue")
      },
      {
        path: "donors/:id",
        name: routeNames.adminDonor,
        meta: {
          authorize: [Role.EDITOR]
        },
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/secure/Donor.vue")
      },
      {
        path: "users",
        name: routeNames.adminUsers,
        meta: {
          authorize: [Role.ADMIN]
        },
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/secure/Users.vue")
      },
      {
        path: "settings",
        name: routeNames.adminSettings,
        meta: {
          authorize: [Role.ADMIN]
        },
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/secure/Settings.vue")
      }
    ]
  },
  {
    path: "/unauthorized",
    name: routeNames.unauthorized,
    meta: { layout: PublicLayout, redrectonOhNo: true },
    component: () =>
      import(/* webpackChunkName: "security" */ "../views/AuthFail.vue")
  },

  // ===== CATCH ALL ====
  {
    path: "*",
    meta: { layout: PublicLayout, redrectonOhNo: true },
    component: () =>
      import(/* webpackChunkName: "errors" */ "../views/NotFound.vue")
  }
]

async function initRouter() {
  userRoles = await store.get("auth/roles")
}

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
  // scrollBehavior moves to the top of the page when swithing routes.  People were not seeing the search
  // button when switching to the tree after having scrolled to the bottom of the homepage
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  initRouter: initRouter()
})

router.beforeEach(async (to, from, next) => {
  let authorize = []
  let authRequired = false

  to.matched.forEach((record) => {
    if (record.meta.authorize) {
      authRequired = true
      record.meta.authorize.forEach((role) => authorize.push(role))
    }
  })
  const currentUser = auth.currentUser

  userRoles = await store.get("auth/roles")

  if (authRequired) {
    if (!currentUser) {
      // not logged in so redirect to login page with the return url
      return next({ name: routeNames.login, query: { returnUrl: to.path } })
    }

    // check if route includes a role restriction
    if (authorize.length) {
      // Check to see if the route's rule restriction is included in the user's Roles
      if (intersection(authorize, userRoles).length === 0) {
        // role not authorised so redirect to home page
        return next({ name: routeNames.home })
      }
    }
  }

  next()
})

export default router
