<!-- **************************************************************************
     TEMPLATE
     ************************************************************************** -->

<template>
  <v-row justify="space-around">
    <HomeActiveContent
      v-if="isActive"
      :banner="banner"
      :bannerText="bannerText"
      :selectionMethod="selectionMethod"
    ></HomeActiveContent>
    <HomeInactiveContent
      v-if="isBefore"
      :start-date="startDate"
      :banner="banner"
      :bannerText="bannerText"
    ></HomeInactiveContent>
    <HomePostActiveContent
      v-if="isAfter"
      :end-date="endDate"
      :banner="banner"
      :bannerText="bannerText"
    ></HomePostActiveContent>
  </v-row>
</template>

<!-- **************************************************************************
     SCRIPT
     ************************************************************************** -->

<script>
import HomeActiveContent from "./../components/HomeActiveContent"
import HomeInactiveContent from "./../components/HomeInactiveContent"
import HomePostActiveContent from "./../components/HomePostActiveContent"
import { appSettingsCollection } from "../services/firebase"

export default {
  name: "Home",
  title: "Home",
  components: {
    HomeActiveContent,
    HomeInactiveContent,
    HomePostActiveContent
  },
  data() {
    return {
      startDate: new Date("2023-11-11T00:00:00"),
      endDate: new Date("2023-12-08T00:00:00"),
      banner: false,
      bannerText: "",
      selectionMethod: "Tree Only"
    }
  },
  computed: {
    isActive() {
      let now = new Date().getTime()
      let start = new Date(this.startDate).getTime()
      let end = new Date(this.endDate).getTime()

      return now >= start && now <= end
    },
    isBefore() {
      let now = new Date().getTime()
      let start = new Date(this.startDate).getTime()

      return now < start
    },
    isAfter() {
      let now = new Date().getTime()
      let end = new Date(this.endDate).getTime()

      return now > end
    }
  },
  created() {
    appSettingsCollection
      .doc("production")
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.startDate = doc.data().startDate.toDate()
          this.endDate = doc.data().endDate.toDate()
          this.banner = doc.data().banner
          this.bannerText = doc.data().bannerText
          this.selectionMethod = doc.data().selectionMethod
        }
      })
  }
}
</script>
